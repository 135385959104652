import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
  <Layout>
    <h1>Hi, thanks for reaching out. We'll be in touch.</h1>
    <p></p>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
